<!--
 * @Description: 新增教务
 * @Author: xiawenlong
 * @Date: 2021-04-22 14:34:47
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-21 17:23:17
-->
<template>
  <div class="student-add">
    <el-table stripe :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="name" label="教务姓名">
        <template slot-scope="scope">
          <el-input v-model="scope.row.studentName"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="教务手机号">
        <template slot-scope="scope"> <el-input v-model="scope.row.mobile"></el-input> </template
      ></el-table-column>
      <el-table-column prop="address" label="身份证/护照">
        <template slot-scope="scope">
          <el-input v-model="scope.row.cardNo"></el-input>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="部门(选填)">
        <template slot-scope="scope">
          <el-select v-model="scope.row.depts" multiple placeholder="请选择">
            <el-option
              v-for="item in deptSelect"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <i
            v-if="scope.$index == tableData.length - 1"
            class="el-icon-circle-plus-outline"
            @click="handleAdd(scope.$index)"
          ></i>
          <i
            v-if="scope.$index != tableData.length - 1"
            class="el-icon-remove-outline"
            @click="handleRemove(scope.$index)"
          ></i>
          <i class="el-icon-refresh" @click="handleClear(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" :loading="loading" @click="create">提交</el-button>
  </div>
</template>
<script>
const PhoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
const IDCardRegex = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
const PassportRegex = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
import { educationalCreate } from '@/api/course'
import to from 'await-to'
export default {
  name: 'StudentAdd',
  data() {
    return {
      loading: false,
      tableData: [{}, {}, {}, {}, {}],
    }
  },
  methods: {
    handleAdd() {
      this.tableData.push({})
    },
    handleRemove(index) {
      this.tableData.splice(index, 1)
    },
    handleClear(index) {
      this.tableData.splice(index, 1, {})
    },
    async create() {
      this.tableData = this.tableData.map((item, index) => ({ ...item, index }))
      let students = this.tableData.filter(value => value.studentName)
      if (this.loading || !students.length) return
      let msg = []
      students.forEach(stu => {
        if (!stu.mobile) msg.push(`请输入第${stu.index + 1}行教务的手机号`)
        if (stu.mobile && !PhoneRegex.test(stu.mobile))
          msg.push(`第${stu.index + 1}行教务手机号格式不正确`)
        if (stu.cardNo && !IDCardRegex.test(stu.cardNo) && !PassportRegex.test(stu.cardNo))
          msg.push(`第${stu.index + 1}行教务身份证/护照格式不正确`)
      })
      if (msg.length) return this.$message.warning(msg.join('，'))
      this.loading = true
      const [, err] = await to(educationalCreate({ students }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push('/college/educational')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-add {
  ::v-deep.el-table {
    tr {
      td {
        &:first-of-type {
          > div {
            width: 40px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            color: #333333;
            margin: 0 10px;
          }
        }
      }
    }
  }
  ::v-deep.el-table__fixed-right {
    i {
      cursor: pointer;
      color: #606266;
      font-size: 30px;
      margin-right: 10px;
      &.el-icon-circle-plus-outline {
        color: #ff7b33;
      }
      &.el-icon-remove-outline {
        color: #f8341e;
      }
    }
  }
  .el-button {
    display: block;
    margin: 50px auto;
  }
}
</style>
